import logo from "../../assets/image/logo-bottom.png";
import local from "../../assets/image/footer/local.png";
import insta from "../../assets/image/footer/instagram.png";
import telegram from "../../assets/image/footer/telegram.png";
import facebook from "../../assets/image/footer/facebook.png";
import email from "../../assets/image/footer/message.png";
import phone from "../../assets/image/footer/phone.png";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer flex flex-col relative z-50 ">
      <div className="footer-top footer-desktop flex justify-between">
        <ul className="footer-list items-start">
          <li className="footer-item">
            <a href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D">
              INSTAGRAM
            </a>
          </li>
          <li className="footer-item">
            <a href="https://t.me/magnumcars">TELEGRAM</a>
          </li>
          <li className="footer-item">
            <a href="https://www.facebook.com/profile.php?id=61556073765688">
              FACEBOOK
            </a>
          </li>
        </ul>
        <ul className="footer-list flex flex-col items-center">
          <li className="footer-item">
            <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D1%86%D1%8C%D0%BA%D0%B0,+282%D0%B0,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+79000/@49.8238147,23.924532,17z/data=!3m1!4b1!4m6!3m5!1s0x473ae73544d00001:0x5ceabbc076412be1!8m2!3d49.8238113!4d23.9271069!16s%2Fg%2F11rg5yq0nn?hl=uk-UK&entry=tts">
              Львів, Городоцька 282а
            </a>
          </li>
          <li className="footer-item">
            <a href="https://maps.app.goo.gl/pctSeWt6cgFNFjVV9">
              Київ, Глибочицька 17
            </a>
          </li>
        </ul>
        <ul className="footer-list flex flex-col items-end">
          <li className="footer-item">
            <a href="mailto: support@magnumcars.com.ua">
              support@magnumcars.com.ua
            </a>
          </li>
          <li className="footer-item">
            <a href="tel:+380662136202">+(38) 066 213 62 02</a>
          </li>
        </ul>
      </div>

      <div className="footer-top footer-mobile flex justify-between">
        <div className="footer-office-wrap">
          <div className="footer-title-ofice">офіси знаходяться</div>
          <ul className="footer-list flex flex-col items-center">
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://www.google.com/maps/place/%D0%B2%D1%83%D0%BB%D0%B8%D1%86%D1%8F+%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%BE%D1%86%D1%8C%D0%BA%D0%B0,+282%D0%B0,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2,+%D0%9B%D1%8C%D0%B2%D1%96%D0%B2%D1%81%D1%8C%D0%BA%D0%B0+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+79000/@49.8238147,23.924532,17z/data=!3m1!4b1!4m6!3m5!1s0x473ae73544d00001:0x5ceabbc076412be1!8m2!3d49.8238113!4d23.9271069!16s%2Fg%2F11rg5yq0nn?hl=uk-UK&entry=tts">
                Львів, Городоцька 282а
              </a>
            </li>
            <li className="footer-item">
              <img src={local} alt=";local"/>
              <a href="https://maps.app.goo.gl/pctSeWt6cgFNFjVV9s">
                Київ, Глибочицька 17
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-network-wrap">
          <div className="footer-title-network">соціальні мережі</div>
          <ul className="footer-list items-start">
            <li className="footer-item">
              <img src={insta} alt="insta"/>
              <a href="https://www.instagram.com/magnumcarss/?igsh=MXd5ZjY2M2ttODhtcQ%3D%3D">
                INSTAGRAM
              </a>
            </li>
            <li className="footer-item">
              <img src={telegram} alt="telegram"/>
              <a href="https://t.me/magnumcars">TELEGRAM</a>
            </li>
            <li className="footer-item">
              <img src={facebook} alt="facebook" />
              <a href="https://www.facebook.com/profile.php?id=61556073765688">
                FACEBOOK
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-contact-wrap">
          <div className="footer-title-contact">контакти</div>
          <ul className="footer-list flex flex-col items-end">
            <li className="footer-item">
              <img src={email} alt="email" />
              <a href="mailto: support@magnumcars.com.ua">
                support@magnumcars.com.ua
              </a>
            </li>
            <li className="footer-item">
              <img src={phone} alt="phone" />
              <a href="tel:+380662136202">+(38) 066 213 62 02</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom px-4">
        <a href="#" className="footer-bottom-logo">
          <img src={logo} alt="logo" className="" />
        </a>
        {/* <div className="footer-policy"> */}
          <a href="https://magnumcars.com.ua/politika-konfidencialnosti/" className="footer-policy">
            Політика Конфіденційності
          </a>
        {/* </div> */}

        <a
          href="https://newstart-digital.com.ua/#ua"
          className="developers font-[Arimo] text-[30px] text-[#fff]"
          target="_blank"
          rel="noopener noreferrer"
        >
          Development by New Start Digital
        </a>
      </div>
    </footer>
  );
};

export default Footer;
